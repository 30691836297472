import React from "react";
import Swiper from "react-id-swiper";

const Autoplay = () => {
    const params = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    };

    return (
        <Swiper {...params}>
            <div className="slide1 slide-content">
                <h1>Servicios Especializados para Nómina con Estrategia Fiscal</h1>
            </div>
            <div className="slide2 slide-content">
                <h1>Prepárate para la posibilidad de generar atractivos rendimientos con nuestro
                    instrumento de inversión</h1>
            </div>
            <div className="slide3 slide-content">
                <h1>Estrategías especializadas para la administración de pagos a Empleados</h1>
            </div>
        </Swiper>
    );
};

export default Autoplay;

