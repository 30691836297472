import React from "react";
import {Link} from 'gatsby'

import Layout from "../components/Layout";
import data from "../../content/resources/services.json";
import Caroucel from "../components/Caroucel";

const IndexPage = () => (
        <Layout>
            <div id="main">
                <Caroucel/>
                <div className="inner">
                    <section id="about_us">
                        <div className="home">
                            <h1 className="home__title">¿Quiénes somos?</h1>
                        </div>

                        <div className="card">
                            <div className="card__content">
                                <h1 className="home__title_card">Skyler Finance</h1>
                                <p className="home__parrafo_card">Es una firma fundada en el 2004. Desde hace más de una
                                    década hemos asesorado a empresas de diferentes ramos en la República Mexicana con gran
                                    éxito.
                                </p>
                                <Link to="/About" className="button">Más información</Link>
                            </div>
                        </div>
                    </section>

                    <section id="services">
                        <div className="home">
                            <h1 className="home__title">Servicios</h1>
                        </div>
                        <section className="tiles">
                            {data.slice(0, 6).map((service) => {
                                return (
                                    <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                        <Link to={service.path} state={{service: service}}>
                                            <h2>{service.name}</h2>
                                            <div className="content">
                                                <p>
                                                    {service.description}
                                                </p>
                                            </div>
                                        </Link>
                                    </article>
                                )
                            })}
                            <div style={{flex: 1, paddingTop: 20, textAlign: 'center'}}>
                                <Link to="/Services" className="button">Ver más productos</Link>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </Layout>
    )
;

export default IndexPage;
